@import '../../constants/vars.scss';

.dealblock-container {
    background: $mainColor;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    width: 25%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include sm {
        width: 100%;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 12px;

        .dealblock-top {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .dealblock-bottom {
            position: absolute;
            background: $mainColor;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 24px;
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
        }
    }
}

.dealblock-header {
    background: $white;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    margin-bottom: 16px;
}

.dealblock-title {
    font-family: HK Grotesk;
    font-size: 20px;
    line-height: 23px;
    color: $white;
    margin-bottom: 8px;
}

.dealblock-deal {
    margin-bottom: 32px;

    @include sm {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.dealblock-label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: $white;
    margin-bottom: 16px;
    @include sm { text-align: center; }
}

.dealblock-tag {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    align-items: center;
}

.dealblock-tag-label, .dealblock-tag-discount {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    margin-right: 6px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: $white;
    padding: 4px 8px;

    &.red {
        background: $red;
    }

    &.blue {
        background: $blue;
    }

    &.green {
        background: $green;
    }
}

.dealblock-price-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(243, 241, 252, 0.3);
    margin-bottom: 24px;
}

.dealblock-price {
    font-family: HK Grotesk;
    font-size: 40px;
    line-height: 47px;
    color: $white;
}
