@import '../../constants/vars.scss';


.private-lesson-block {
    &-container {
        margin: 32px 32px 20px;
    }

    &-title {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        color: $mainColor;
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;
        align-items: baseline;

        .lesson-tic {
            width: 12px;
            height: 12px;
            background: $white;
            border: 2px solid $grey;
            box-sizing: border-box;
            border-radius: 12px;
            margin-right: 16px;
        }
    }

    &-line {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: $black;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
    }

    &-radio {
        margin-top: 8px;
        display: flex;
        flex-direction: row;

        div {
            margin-left: 24px;

            @include sm {
                margin-left: 6px;
            }

            &:first-child {
                margin-left: 0;
            }


        }


    }

    &-instructors {
        font-family: HK Grotesk;
        font-size: 16px;
        line-height: 150%;
        color: $mainColor;
        margin: 11px 0 16px;
    }
}

.error-text {
    color: $red;
}