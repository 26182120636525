@import '../../constants/vars.scss';

.alert-box {
  background-color: $grey;
  color: $mainColor;
  width: fit-content;
  padding: 1rem;
  border-radius: 5px;

  button {
    background-color: transparent;
    border: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &-container {
    display: flex;
    justify-content: center;
    margin: 3rem 0;
  }

  &-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 150%;
    color: $mainColor;
    display: flex;
    justify-content: center;
  }

  &-content {
    font-family: Open Sans;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    color: $mainColor;
    display: flex;
    justify-content: center;
  }
}

.is-button {
  cursor: pointer;
}