@import '../../constants/vars.scss';

.scene-station {
    &-block {
        margin-top: 77px;
        display: flex;
        flex-direction: column;
        align-items: center;
        button {

            background: none;
            outline: none;
            border: none;

            img {
                width: 61.46px;
                height: 64px;
            }
        }

        &-text {
            margin-top: 25px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 170%;
            color: $mainColor;
            @include sm {
                padding: 0 32px;
            }
        }
    }


    &-shadow {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: auto;
        max-height: 40%;
        object-fit: cover;
        z-index: -1;
    }

    &-footer {
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 0;
        background-color: #FAF9FF;
        z-index: -1;
        @include sm {
            max-height: 50px;
        }
    }
}

.progress-bar-20 {
    width: 20%;
    height: 4px;
    background: $yellow;
}
