@import '../../constants/vars.scss';

.footer {
    position: fixed;
    bottom: 0;
    background: #FAF9FF;
    padding: 20px 130px;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include sm {
        padding: 0;
    }

    &-error {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: $red;
        margin-bottom: 10px;
    }

    &-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;




        @include sm {
            justify-content: center;
            padding: 12px 24px;
        }

        > button {
            margin-right: 42px;

            @include sm {
                margin-right: 15px;
                font-size: 10px;
                padding: 6px 24px;
            }
        }
    }
}
