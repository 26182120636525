@import '../../constants/vars.scss';

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $mainColor;
  width: 100%;
  height: 80px;
  padding: 0 100px;

  @include sm {
    padding: 0 24px;
  }

  .header-button {
    font-family: HK Grotesk, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: $white;
    z-index: 1;

    @include sm {
      span {
        display: none;
      }
    }
  }


}



.header-button > img, .header-logo > img {
  margin-right: 8px;
}

.header-skifamily-logo {
  width:100%;
  text-align: center;
  position: absolute;
  margin-left:-100px;
  .skifamily-logo{
    margin:auto;
    width:100%
  }
}

.header-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: HK Grotesk, sans-serif;
  font-size: 15.96px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: $white;

  @include sm {
    span {
      display: none;
    }
  }
}
