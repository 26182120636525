@import '../../constants/vars.scss';

.dropdown {
  &-component {
    width: 30%;
    display: block;
    margin: 0.5rem;

    @include sm {
      width: 100%;
    }
  }

  &-label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 170%;
    color: $mainColor;
    opacity: 0.3;
    border: none;
    background: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;

    &.filled {
      opacity: 1;
    }

    @include sm {
      font-size: 16px;
    }
  }

  &-container {
    border-bottom: 2px dashed $mainColor;
    position: relative;
    height: 31px;
    padding: 0 0 8px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    button {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.error {
      border-bottom: 2px dashed $red;

      .dropdown-label {
        color: $red;
      }
    }
  }


  &-options {
    width: 100%;
    min-width: 200px;
    background: $white;
    box-shadow: 8.43853px 8.43853px 33.7541px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    z-index: 1;
    position: relative;

    .check {
      position: absolute;
      right: 20px;
    }

    &-container {
      position: absolute;
      top: 34px;
      left: 0;
      width: 100%;
      z-index: 10;

      @include sm {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
      }
    }
  }

  &-option {
    border-top: 1px solid $grey;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
    color: #5A3FDC;
    /*opacity: 0.5;*/
    padding: 8px 33px 12px;
    width: initial;
    display: flex;

    &:hover {
      font-weight: bold;
      opacity: 1;
      background-color: $grey;
    }

    &:first-child {
      border-top: unset;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    @include sm {
      font-size: 16px;
    }

  }

  &-background {
    display: none;
    @include sm {
      position: fixed;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      opacity: 0.5;
      z-index: 1;
    }
  }

  &-arrow {
    margin-left: 10px;
    width: 10px;
    height: 10px;

    &.up {
      transform: rotate(-180deg);
    }
  }
}
