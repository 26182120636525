@import '../../constants/vars.scss';

.scene-validation {
  padding: 48px 101px 180px;
  width: 75%;
  margin-left: 12.5%;

  form {
    display: grid;
  }

  @include sm {
    padding: 18px 16px 118px 16px;
    margin-left: 0;
    width: 100%;
  }

  &-subtitle {
    margin-left: 85px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 160%;
    color: $black;

    @include sm {
      margin-left: 0;
      text-align: center;
    }
  }

  &-text {
    margin-top: 56px;
    margin-left: 85px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 160%;
    color: $black;
    @include sm {
      margin-left: 0;
      text-align: center;
    }
  }

  textArea {
    background: #FFFFFF;
    border: 2px solid $grey;
    box-sizing: border-box;
    border-radius: 12px;
    margin-top: 27px;
  }

  &-instruction {
    &-container {
      padding-left: 84px; //  Equals icons width + margin right of icons
      width: 90%;

      @include sm {
        padding-left: 0;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-title {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 150%;
      color: $mainColor
    }

    &-content {
      font-family: Open Sans;
      font-style: normal;
      font-size: 16px;
      line-height: 150%;
    }
  }

  &-button {
    background-color: $green;
    justify-self: center;
    margin-bottom: 2rem;
  }

  hr {
    color: $mainColor;
  }
}
