@import '../../constants/vars.scss';

.lesson-recap-data {
    background: $mainColor;
    border-radius: 8px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    text-align: right;
    color: $white;
    padding: 9px 8px 9px 16px;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;

    img {
        margin-left: 13px;
        opacity: 0.3;
    }
}
