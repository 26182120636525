@font-face {
    font-family:"Open Sans";
    src: url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family:"HK Grotesk";
    src: url("../assets/fonts/HKGrotesk-Regular.otf") format("truetype");
}

@mixin sm {
    @media (max-width: 1024px) {
        @content
    }
}

$white: #FFFFFF;
$black: #0C1139;

$grey: #EFECFC;
$lightGrey: #FAF9FF;
$lighterGrey: #F3F1FC;
$calendarGrey: #E2DCF8;

$mainColor: #5A3FDC;

$yellow: #FFC501;
$red: #FF5800;
$blue: #3894FF;
$green: #47DD9D;
