@import '../../constants/vars.scss';

.lesson-detail {
    padding: 48px 101px 100px;

    @include sm {
        padding: 28px 18px 40px;
    }

    .lesson-block {

        &-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: $white;
            border: 2px solid $grey;
            box-sizing: border-box;
            border-radius: 12px;
            margin: 40px 0 32px;

            @include sm {
                position: relative;
                flex-direction: column-reverse;
            }
        }

        &-detail {
            padding: 32px 32px 20px;
            @include sm {
                padding: 28px 28px 140px;

                > .checkbox {
                    margin-bottom: 12px;
                    span {
                        font-size: 14px;
                        line-height: unset;
                    }
                }
            }


        }

        &-title {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 150%;
            color: $mainColor;
            margin-bottom: 16px;
            display: flex;
            flex-direction: row;
            align-items: baseline;

            @include sm {
                .tic {
                    display: none;
                }
            }
        }

        &-line {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: $black;
            margin-bottom: 24px;
            display: flex;
            flex-direction: column;

            @include sm {
                margin-bottom: 6px;
            }

        }

        &-radio {
            margin-top: 8px;
            display: flex;
            flex-direction: row;

            @include sm {
                flex-direction: column;

                .radio-group-container {
                    margin: 0 0 12px;
                }
            }

            > div {
                margin-left: 24px;

                &:first-child {
                    margin-left: 0;
                }

                @include sm {
                    margin-left: 0;
                }
            }

            &-description {
                font-family: HK Grotesk;
                font-style: italic;
                font-size: 16px;
                line-height: 32px;
                display: flex;
                align-items: center;
                letter-spacing: 0.2px;
                color: $mainColor;
                margin-left: 21px;

                @include sm {
                    margin-top: 0;
                }
            }

            &-subtitle {
                margin-bottom: 8px;
            }

            &-desktop {
                display: block;

                @include sm {
                    display: none;
                }
            }

            &-mobile {
                display: none;

                @include sm {
                    display: flex;
                    flex-direction: column
                }
            }


        }
    }
}
