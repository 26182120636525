@import '../../../constants/vars.scss';

.seller-station-selection {

  &-container {
    padding: 2rem 7% 0;
  }

  &-number-selector {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 170%;
    color: $black;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

}
