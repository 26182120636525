@import '../../constants/vars.scss';


.order-details {
  margin: 5rem 0;

  &-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: $mainColor;
  }

  &-box {
    background-color: $lighterGrey;
    padding: 2rem;

    &-label {
      font-family: Open Sans;
      font-style: normal;
      font-size: 18px;
      line-height: 150%;
      color: $black;
    }

    &-value {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
      color: $black;
    }

    hr {
      color: $lightGrey !important;
    }
  }
}