@import '../../constants/vars.scss';

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-case {
    background: white;
    border: 2px solid #E2DCF8;
    box-sizing: border-box;
    border-radius: 8px;
    color: white;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-missing {
      background: white;
      border: 2px solid $red;
      box-sizing: border-box;
      border-radius: 8px;
      color: $red;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.checked {
      background: #47DD9D;
      border: 2px solid #47DD9D;

      img  {
        display: block;
      }
    }
  }
}
