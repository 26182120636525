@import '../../constants/vars.scss';

.progress-bar-60 {
  width: 60%;
  height: 4px;
  background: $yellow;
}

.step3-loader-container {
  padding: 2rem 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .MuiBox-root {
    &.MuiBox-root-2, &.jss1 {
      width: 50%;
    }
  }

  .calendar-loader-text-wrapper {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}


.step3-no-availability-container {
  padding: 5rem 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .no-availability-header {
    margin-bottom: 3rem;
    @include sm{
      font-size: 1.2rem;
    }
  }

  .contact-us-text {
    color: $mainColor;
    font-weight: bold;
    font-size: 1.2rem;
    @include sm{
      font-size: 0.9rem;
    }
  }

  .contact-link-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include sm {
      flex-direction: column;
    }


    .phone-link {
      margin-right: 1rem;

      @include sm {
        margin-right: 0;
        margin-bottom: 1rem;

      }
    }
  }

  .contact-pro {
    margin-top: 5rem;
  }

}
