@import '../../constants/vars.scss';

.lesson-dropdown {
    border-bottom: 2px dashed $mainColor;
    position: relative;
    height: 31px;
    padding: 0 0 8px;
    min-width: 150px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &-label {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 170%;
        color: $mainColor;
        opacity: 0.3;
        border: none;
        background: none;
        box-shadow: none;
        outline: none;
        cursor: pointer;

        &.filled {
            opacity: 1;
        }

        @include sm {
            font-size: 16px;
        }
    }

    &.error {
        border-bottom: 2px dashed $red;

        .lesson-dropdown-label {
            color: $red;
        }
    }

    &-options-container {
        position: absolute;
        top: 34px;
        width: 100%;
        z-index: 10;

        @include sm {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
        }
    }


    &-options {
        width: 100%;
        background: $white;
        box-shadow: 8.43853px 8.43853px 33.7541px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        z-index: 1;
        width: fit-content;
        display: flex;
        flex-direction: row;

        @include sm {
            position: relative;
            flex-direction: column;
        }
    }



    &-option {
        border-top: 1px solid $grey;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 170%;
        color: $mainColor;
        padding: 8px;
        max-width: 216px;
        width: max-content;


        &:hover  {
            font-weight: bold;
            opacity: 1;
            background-color: $grey;
        }

        &:first-child {
            border-top: unset;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            border-right: 2px solid $grey;

            @include sm {
                border-right: none;
                border-bottom: 1px solid $grey;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 12px;
            }
        }
        &:last-child {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            @include sm {
                border-bottom-right-radius: 12px;
                border-top-right-radius: 0px;
            }
        }

        &-label {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 170%;
            color: $mainColor;
            background-color: #FAFAFB;
            text-align: center;
            padding: 9px 31px;
            border-radius: 12px;
            margin-bottom: 16px;

            /*&.selected {*/
                background-color: $grey;
            /*}*/
        }

        &-descriptions {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            color: $black;

            div {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                margin-bottom: 8px;
            }
        }

        &-tic {
            background-color: $grey;
            width: 5px;
            height: 5px;
            margin-right: 8px;
        }
    }

    &-background {
        display: none;
        @include sm {
            position: fixed;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            opacity: 0.5;
            z-index: 1;
        }
    }

    &-arrow {
        margin-left: 10px;
        width: 10px;
        height: 10px;
        &.up {
            transform: rotate(-180deg);
        }
    }

}
