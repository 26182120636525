@import '../../constants/vars.scss';
@import '~antd/dist/antd.css';

.date-picker {
    border-bottom: 2px dashed $mainColor;
    position: relative;
    height: 31px;
    padding: 0 0 8px;
    min-width: 150px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-label {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 170%;
        color: $mainColor;
        opacity: 0.3;

        &.filled {
            opacity: 1;
        }
    }

    &-dropdown {
        position: absolute;
        top: 54px;
        min-width: 300px;
        width: 100%;
        background: $white;
        box-shadow: 8.43853px 8.43853px 33.7541px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        z-index: 1;
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include sm {
            min-width: unset;
        }
    }

    &-background {
        display: none;
        @include sm {
            display: block;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $black;
            opacity: 0.5;
            z-index: 5;
        }
    }

    &-instructions {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 180%;
        color: $mainColor;
        background: #EFECFB;
        border-radius: 16px;
        padding: 6px 16px 11px;
        margin: 11px 33px;
    }

    &-picker {
        margin: 0 33px 5px;
        position: relative;
    }


    &-arrow {
        margin-left: 16px;
        width: 10px;
        height: 10px;
        &.up {
            transform: rotate(-180deg);
        }
    }

    &-top {
        border: none !important;
        background: none;

        .ant-picker-suffix {
            display: none;
        }

        input, input::placeholder, .ant-picker-range-separator {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 170%;
            color: #5A3FDC;
            opacity: 0.5;
            text-align: center;
        }


        .ant-picker-range-separator {
            opacity: 1;
        }

        &.filled {
            input, input::placeholder {
                opacity: 1;
            }
        }

        .ant-picker-active-bar {
            background: none;
        }

    }

    &-bottom {
        .ant-picker-next-icon {
            transform: unset;
            width: 15px;
            height: 10px;

            &::before {
                border: 0px;
                background-image: url('../../assets/icons/arrow-right.svg');
                width: 15px;
                height: 10px;
            }
        }
        .ant-picker-prev-icon {
            transform: unset;
            width: 15px;
            height: 10px;

            &::before {
                border: 0px;
                background-image: url('../../assets/icons/arrow-left.svg');
                width: 15px;
                height: 10px;
            }
        }
        .ant-picker-panel-container {
            display: flex;
            flex-direction: column-reverse;
            background: #FFFFFF;
            box-shadow: 8.43853px 8.43853px 33.7541px rgba(0, 0, 0, 0.08);
            border-radius: 12px;
            padding: 0px 27px;
        }

        @include sm {
            .ant-picker-panels {
                flex-direction: column;
                align-items: center;
            }
        }

        .ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn, .ant-picker-year-btn {
            display: none;
        }
        .ant-picker-header-prev-btn, .ant-picker-header-next-btn{
            margin-top: 2px;
            width: 32px;
            height: 32px;
            background: #EFECFC;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ant-picker-month-btn {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 180%;
            color: $mainColor;
        }

        .ant-picker-content {
            thead {
                margin: 10px 0px;
            }
            th {
                color: $calendarGrey;
            }

            td {
                &.ant-picker-cell-disabled::before {
                    background-color: $lightGrey;
                }
                &.ant-picker-cell-disabled {
                    div {
                        opacity: 0.3;
                    }
                }
                &.ant-picker-cell-today > div::before {
                    border-color: $calendarGrey;
                    border-radius: 20px;
                }

                .ant-picker-cell-inner {
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 180%;
                    color: $black;

                }

                &.ant-picker-cell-range-start,
                &.ant-picker-cell-range-end{
                    div {
                        color: $white;
                        background: #5A3FDC;
                        border-radius: 20px !important;
                    }
                }

                &.ant-picker-cell-in-range::before,
                &.ant-picker-cell-range-start::before,
                &.ant-picker-cell-range-end::before {
                    background: #EFECFB;
                }

                &.ant-picker-cell-range-hover-start::before,
                &.ant-picker-cell-range-hover-end::before {
                    background: $lighterGrey !important;
                }

                &.ant-picker-cell-range-hover {
                    &:last-child::after {
                        border-right: 1px dashed $mainColor;
                    }
                    &:first-child::after {
                        border-left: 1px dashed $mainColor;
                    }
                    &:not(.ant-picker-cell-in-range)::after {
                        border-top: 1px dashed $mainColor;
                        border-bottom: 1px dashed $mainColor;
                    }
                }

                .ant-picker-cell-inner::after { background: $lighterGrey !important; }
            }
        }
    }

}
