@import '../../../constants/vars.scss';

.seller-date-selection-container {
    padding: 2rem 7% 180px;
    position: absolute;
    top: 5rem;
    right: 0;
    pointer-events: none;

    .date-selection-add {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
}

.seller-date-selection-number-selector{
    pointer-events: all;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 170%;
    color: $black;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}
