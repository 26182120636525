@import '../../constants/vars.scss';

.tunnel-informations {
  display: flex;
  flex-direction: row;
  padding: 48px 101px 180px;

  &-user-form{
    margin-bottom: 32px;
    margin-top: 16px;
  }
  &-checkbox-container {
    margin-bottom: 0.5rem;
  }

  &-checkbox-has-account{
    padding-left: 0.3rem;
  }

  &-error {
    margin: auto;
    display: flex;
    flex-direction: column;

    .redirection-button {
      max-width: 66%;
      margin: 2rem auto 0;
      background-color: $red;
    }

    div {
      margin: 0 auto;
    }

    &-container {
      width: 100%;
      height: 80vh;
      display: flex;
    }
  }

  &-loader {
    margin: auto;

    &-container {
      width: 100%;
      height: 80vh;
      display: flex;
    }
  }

  @include sm {
    padding: 28px 32px;
    flex-direction: column;
  }

  &-left {
    display: flex;
    flex-direction: column;
    margin-right: 32px;

    &-buttons {
      display: flex;
      flex-direction: row;

      > button:first-child {
        margin-right: 12px;
      }
    }
  }

  &-right {
    margin-top: 76px
  }

  &-text {
    margin-left: 80px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #0C1139;
    margin-bottom: 32px;
    @include sm {
      margin-left: 0;
      margin-top: 12px;
      text-align: center;
    }
  }

  &-line {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    @include sm {
      flex-direction: column;
      margin-bottom: 0;

      > div, > input {
        margin-bottom: 16px;
        width: 100%;
      }
    }

    &:last-child {
      margin-bottom: 40px;
    }

    input:first-child {
      margin-right: 10px;
    }
  }

  &-cguline {
    margin-left: 24px;
    margin-bottom: 48px;
  }

  &-cgu {
    margin-left: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #0C1139;

    &-missing {
      margin-left: 24px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: $red;
    }
  }

  @include sm {
    .validation-button {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      border-radius: 0;
      display: flex;
      justify-content: center;
      z-index: 1;
    }
  }
}

.error-message-tunnel-info {
  color:red;
}
