@import '../../constants/vars.scss';

.stud-ref-form {
  &-container {
    border: 2px lightgray solid;
    width: 100%;
    border-radius: 15px;
    margin: 3rem 0;
    padding: 2rem;

    h2 {
      margin: 0.5rem 0;
      display: flex;
      justify-content: center;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 150%;
      color: $mainColor;
    }
  }
}