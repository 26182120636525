@import '../../constants/vars.scss';


.level-selector {
    position: relative;
    height: 48px;
    max-width: 328px;
    width: 100%;

    &-background {
        position: absolute;
        top: -4px;
        left: 0;
        width: 100%;
        height: 100%;
        background: $lightGrey;
        border-radius: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
    }

    &-line {
        background: $mainColor;
        opacity: 0.1;
        border-radius: 10px;
        height: 2px;
        width: 100%;
    }

    &-values {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: calc( 100% - 8px );
        height: calc( 100% - 8px );
        padding: 4px;
    }

    &-value {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            div:not(.selected) {
                width: 8px;
                height: 8px;
            }
        }
    }

    &-icon {
        width: 4px;
        height: 4px;
        border-radius: 10px;

        &.white { background: $white; }

        &.yellow { background: $yellow; }

        &.green { background: $green; }

        &.blue { background: $blue; }

        &.red { background: $red; }

        &.black { background: $black; }

        &.selected {
            box-shadow: 8.43853px 8.43853px 33.7541px rgba(0, 0, 0, 0.08);
            border-radius: 62px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;

            img {
                padding: 4px;
                background-color: $white;
                border-radius: 15px;
                display: block;
                width: 20.57px;
                height: 20.57px;
            }
        }
        img     {
            display: none;
        }
    }


}
