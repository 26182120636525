@import '../../../constants/vars.scss';


.lesson-details {

  &-information {
    margin-left: 1rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 170%;
    color: $black;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-group {
    display: inline-flex;
    margin: 0 24px;

    .lesson-details-description {
      margin-top: 14px;
    }
  }

  &-container {
    margin-left: 34px;
    margin-top: 34px;
  }

  &-line {
    display: flex;
  }

  &-description {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 170%;
    color: $black;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}