@import '../../constants/vars.scss';

.product-details {
  margin: 5rem 0;

  &-right {
    width: 75%;
  }

  &-row-0 {
    background-color: $lightGrey;
    display: flex;
    padding: 2rem;
  }

  &-row-1 {
    background-color: $lighterGrey;
    display: flex;
    padding: 2rem;
  }

  &-sub {
    font-family: Open Sans;
    font-style: normal;
    font-size: 18px;
    line-height: 150%;
    color: $black;
  }

  &-main {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    color: $black;
  }

  hr {
    color: $lightGrey !important;
  }
}