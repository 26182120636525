@import '../../constants/vars.scss';

.tic {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    border: 2px solid #EFECFB;
    box-sizing: border-box;
    border-radius: 12px;
    margin-right: 16px;
}
