@import '../../constants/vars.scss';

.stud-form {
  &-dropdown {
    margin: 0.3rem;
    height: 54px;
    width: 100%;

    @include sm {
      margin: 0;
    }

    &-options {
      margin-top: 1rem;
    }
  }

  &-container {
    padding: 2rem;
    margin: 2rem;

    @include sm {
      margin: 2rem 0;
      padding: 2rem 0;
    }

    h3 {
      color: $mainColor;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
    }

    label {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 150%;
    }

    input {
      border-radius: 15px;
      margin: 0.3rem;

      @include sm {
        margin: 0;
      }
    }

  }

  &-input {
    &-required {
      color: $mainColor;
    }

    &-container {
      margin: 0.5rem;
    }

    &-comment {
      width: calc(60% + 1rem) !important; // The 1rem margin that is skipped, comment is 2-unit long

      @include sm {
        width: 100% !important;
      }
    }

    &-group {
      display: flex;
      flex-wrap: wrap;

      input {
        width: 100%;
      }
    }
  }
}