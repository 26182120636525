@import '../../constants/vars.scss';

.lesson-selection-container {
    padding: 3rem 7% 100px;

    @include sm {
        padding: 2rem 7% 80px;
    }

    .lesson-selection-add {
        display: flex;
        justify-content: center;
        margin-top: 24px;

        @include sm {
            width: 100%;
        }
    }
}

.lesson-selection-number-selector{
    margin-top: 45px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 170%;
    color: $black;
    display: flex;
    flex-direction: row;
    align-items: baseline;

    @include sm {
        font-size: 16px;
        position: relative;
        display: block;
        padding-left: 30px;
        .tic {
            position: absolute;
            left: 0;
            top: 7px;
        }
        .dropdown-container, .tic {
            display: inline-block;
        }
    }

    >div {
        margin: 0 8px;
    }
}
