@import '../../../constants/vars.scss';

.seller-lesson-lesson-index {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 170%;
  color: $mainColor;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.seller-lesson-selection-container {
  padding: 2rem 7% 180px;

  hr {
    margin: 2rem 0 !important;
    color: $mainColor;
  }

  .lesson-selection-add {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}

.seller-lesson-selection-number-selector {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 170%;
  color: $black;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
