@import '../../constants/vars.scss';

.lesson {
    &-line {
        margin-top: 40px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 170%;
        color: $black;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;

        @include sm {
            font-size: 16px;
            position: relative;
            display: block;
            padding-left: 30px;
            .tic {
                position: absolute;
                left: 0;
                top: 10px;
            }
            .lesson-dropdown, .dropdown-container, .tic {
                display: inline-block;
            }
        }

        &.no-tic {
            margin-left: 34px;
            margin-top: 5px;

            @include sm {
                margin-left: 0;
            }
        }

        >div {
            margin: 0 8px;
        }
    }

    &-delete-block {
        display: flex;
        flex-direction: row;;
    }

    &-delete {
        font-family: Open Sans;
        color: $mainColor;
        opacity: 0.3;
        margin-left: 20px;
    }
}
