@import '../../constants/vars.scss';

.lesson-recap {

    &-total {
        position: relative;

        &-block {
            display: flex;
            flex-direction: column;
            background: $white;
            border: 2px solid $grey;
            box-sizing: border-box;
            border-radius: 12px;
            padding: 24px;
            width: fit-content;
            height: fit-content;
            min-width: 320px;

            @include sm {
                min-width: unset;
                width: 100%;
            }
        }

        &-container {
            position: sticky;
            top: 12px;
            display: flex;
            flex-direction: column;

            @include sm {
                margin-bottom: 50px;
            }

            > button {
                margin-top: 16px;
                min-width: 320px;
                display: flex;
                justify-content: center;

                @include sm {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    border-radius: 0;
                    min-width: unset;
                    width: 100%;
                    padding: 24px;
                }
            }
        }

        &-line {
            display: flex;
            border-bottom: 2px solid $grey;
            padding: 16px 0;
            &.row {
                flex-direction: row;
                justify-content: space-between;
            }
            &.col {
                flex-direction: column;
            }

            &:last-child {
                border: none;
            }
        }

        &-subline {

            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &-title {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 150%;
            color: $mainColor;
        }

        &-text {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 150%;
            text-align: right;
            color: $black;

            &.bigger {
                font-size: 18px;
            }
        }

        &-secondarytext {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: $black;
            opacity: 0.5;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-right: 10px;
        }

        &-discount {
            position: relative;

            input {
                background: $lightGrey;
                border-radius: 12px;
                border: none;
                padding: 15px 45px 15px 15px;
                width: calc( 100% - 60px );
                font-family: Open Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                color: $mainColor;
                outline: none;
            }

            img {
                position: absolute;
                right: 20px;
                top: 17px;
                width: 20px;
                height: 20px;
            }
        }
    }
}
