@import '../../constants/vars.scss';

.payment-form {
  &-container-error {
    border: 1px dashed $red;

    &::placeholder {
      color: $red;
    }
  }

  &-input-container {
    background: #FAF9FF;
    border-radius: 12px;
    border: none;
    padding: 15px 45px 15px 15px;
    width: calc(50% - 10px);
    margin-right: 10px;
    outline: none;

    &-invalid {
      background: #FAF9FF;
      border-radius: 12px;
      border: 1px dashed $red;
      padding: 15px 45px 15px 15px;
      width: calc(50% - 10px);
      margin-right: 10px;
      outline: none;
    }

    &::placeholder {
      color: $mainColor;
      opacity: 0.3;
    }
  }
}