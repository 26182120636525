@import '../../constants/vars.scss';

.lesson-recap-detail {
    @include sm {
        > button {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 16px;
        }
    }

    &-block {
        display: flex;
        flex-direction: column;
        background: $lightGrey;
        border-radius: 12px;
        margin-bottom: 16px;
        padding: 24px;
    }

    &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 24px;
        border-bottom:  solid 2px $calendarGrey;
        margin-bottom: 24px;

        &-left, &-right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    &-data {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &-pass {
        &-text {
            width: 100%;
            border: 2px solid $lightGrey;
            box-sizing: border-box;
            border-radius: 12px;
            padding: 10px;
            margin-left: 12px;

            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: $black;

            &.checked {
                border: 2px solid $green;
            }

            span {
                font-family: Open Sans;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 150%;
                color: $mainColor;
            }
        }
    }

    &-index {
        background: $mainColor;
        border-radius: 12px;
        padding: 10px 13px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        color: $white;
    }

    &-privacy {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        color: $mainColor;
        margin: 0 8px 0 4px;
    }

    &-price {
        background: $blue;
        border-radius: 8px;
        padding: 14px 20px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        text-align: right;
        color: $white;
    }

    &-delete {
        background: $white;
        border-radius: 8px;
        margin-left: 8px;
        padding: 20px;

        img {
            width: 13px;
            height: 16px;
        }
    }
}
