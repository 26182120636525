@import '../../constants/vars.scss';

.skifamily-button {
    border-radius: 60px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 16px 40px;
    cursor: pointer;
    border: none;
    outline: none;

    @include sm {
        padding: 16px 22px;
    }

    &.primary-button {
        background: $mainColor;
        color: $white;
    }

    &.validation-button {
        background: $green;
        color: $white;
    }

    &.cancel-button {
        color: $mainColor;
        background: rgba(90, 63, 220, 0.3);
    }

    &.white-button {
        color: $mainColor;
        background: $white;
        border: 1px solid $mainColor
    }

    &.disabled-button {
        color: $white;
        background: $grey;
    }

    &.error-button {
        color: $white;
        background: $red;
    }
}
