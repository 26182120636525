@import '../../../constants/vars.scss';

.seller-public-lesson {
    &-line {
        display: inline;
    }
    &-start-time {
        font-family: HK Grotesk;
        font-size: 16px;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: $mainColor;
        padding-bottom: 24px;
        width: 100%;
        border-bottom: 1px solid $lighterGrey;

        @include sm {
            padding-bottom: 6px;
        }
    }

    &-level {
        display: flex;
        flex-direction: row;

        @include sm {
            flex-direction: column;
        }

        .level-selector {
            margin-right: 16px;
            @include sm {
                margin-right: 0;
                margin-bottom: 12px;
            }
        }


        &-checkbox {
            margin-left: 12px;
            font-family: HK Grotesk;
            font-size: 16px;
            line-height: 32px;
            display: flex;
            align-items: center;
            letter-spacing: 0.2px;
            color: $red;
        }

        &-title {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: $black;
            margin-top: 23px;
            margin-bottom: 24px;

            @include sm {
                margin-top: 6px;
                margin-bottom: 6px;
            }
        }

        &-name {
            font-family: HK Grotesk;
            font-size: 16px;
            line-height: 32px;
            display: flex;
            align-items: center;
            letter-spacing: 0.2px;
            color: $mainColor;
            margin-top: 16px;

            @include sm {
                margin-top: 6px;
            }
        }

        &-description {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: $black;
            text-align: left;
            margin-bottom: 22px;
        }
    }

    &-warning {
        margin-top: 13px;
        font-family: HK Grotesk;
        font-size: 16px;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: $red;
    }
}
