@import '../../constants/vars.scss';


.lesson-calendar {
  width: 100%;
  display: flex;
  flex-direction: column;

  &-week-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    border: 2px solid $calendarGrey;
    box-sizing: border-box;
    border-radius: 12px;
    @include sm {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
    }
  }

  &-option-wrapper {
    display: flex;
    flex-direction: column;
    border-right: 2px solid $calendarGrey;
    flex-grow: 1;

    &:last-child {
      border-right: none;
      @include sm {
        border-bottom: none;
      }
    }

    @include sm {
      flex-direction: row;
      border-bottom: 2px solid $calendarGrey;
      border-right: none;
    }

  }

  &-header {
    width: 100%;
    min-width: 10rem;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: HK Grotesk;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: $mainColor;
    border-bottom: 2px solid $calendarGrey;

    @include sm {
      height: 150px;
      padding: 4px;
      border-right: 2px solid $calendarGrey;
      border-bottom: none;
      min-width: 7rem;
      max-width: 7rem;
      &:last-child {
        border-bottom: none;
      }
    }


  }

  &-lines-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-line {
    display: flex;
    flex-direction: row;
    height: 100%;

    &:last-child {
      border-bottom: none;
    }

    @include sm {
      flex-direction: column;
      width: 100%;
    }

  }

  &-cell {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-right: 2px solid $calendarGrey;
    padding: 16px;

    &:last-child {
      border-right: none;
    }

    @include sm {
      border-right: none;
      border-bottom: 2px solid $calendarGrey;
      padding: 5px;
      justify-content: center;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &-time {
    padding: 4px 8px;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $lightGrey;
    border-radius: 8px;
    font-family: HK Grotesk;
    font-size: 13px;
    line-height: 150%;
    color: $mainColor;
    cursor: pointer;

    @include sm {
      padding: 2px 5px;
      margin-bottom: 3px;
    }

    &.selected {
      background: $mainColor;
      color: $lightGrey;
    }

    &.disabled {
      background: $lightGrey;
      color: #CEC5F5;
    }
  }
}
