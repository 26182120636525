@import '../../constants/vars.scss';


.lesson-recap {
    padding: 48px 101px;

    @include sm {
        padding: 28px 32px;
    }

    &-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;

        @include sm {
            flex-direction: column;
        }
    }

    &-detail {
        display: flex;
        flex-direction: column;
        margin-right: 32px;
        width: 100%;

    }
}

.progress-bar-100 {
    width: 100%;
    height: 4px;
    background: $yellow;
}
