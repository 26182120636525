@import '../../constants/vars.scss';

.input {
  background: #FAF9FF;
  border-radius: 12px;
  border: none;
  padding: 15px 45px 15px 15px;
  width: calc(50% - 10px);
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: $mainColor;
  outline: none;

  &::placeholder {
    color: $mainColor;
    opacity: 0.3;
  }

  &-container {
    display: block;
    width: 30%;

    @include sm {
      width: 100%;
    }
  }
}

.span-error {
  color: $red;
}
