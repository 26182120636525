@import '../../constants/vars.scss';

.ski-set {
  margin: 4rem 0;

  &-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: $mainColor;
  }

  &-box {
    border: 2px solid $calendarGrey;
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 27px 78px 27px 63px;


    @include sm {
      flex-direction: column;
      padding: 27px;
    }

    &-left {
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 150%;
      color: $mainColor;
      @include sm {
        margin-bottom: 12px;
      }
    }

    &-text {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: $black;
      margin-bottom: 12px;
    }
  }

  &-view-offer-container {
    img {
      width: 250px;
      height: 64.68px;
      margin: 0 auto;

      @include sm {
        width: auto;
        height: auto;
        max-width: 100%;
        margin-left: 0;
      }
    }

    button {
      margin: 0 auto;
      background-color: #6f5ec2;
    }
  }
}