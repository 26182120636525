@import '../../constants/vars.scss';

.radio-group {
  &-options {
    background: $lightGrey;
    border-radius: 120px;
    display: flex;
    flex-direction: row;

    align-items: center;
    padding: 2px;
    width: fit-content;
  }

  &-option {
    padding: 4px 16px;
    margin: 0 2px;
    font-family: HK Grotesk;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: $mainColor;
    cursor: pointer;

    @include sm {
      font-size: 14px;
      padding: 4px 16px;
    }

    &.selected {
      background: $mainColor;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      border-radius: 120px;
      color: $white;
      cursor: pointer;
    }

    &.disabled {
      color: darkgray;
      text-decoration: line-through;
    }
  }

  &-container {
    width: fit-content;

    img {
      display: none;
    }

    @include sm {
      position: relative;

      &.scroller {
        max-width: 100%;

        border-radius: 0;

        > img {
          display: block;
          position: absolute;
          right: 5px;
          top: 15px;
          transform: rotate(-90deg);
        }

        .radio-group-options {
          border-radius: 0;
          overflow: scroll;
          max-width: 100%;
          background: none;
        }

        .radio-group-option {
          background: $lightGrey;
          border-radius: 120px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
          white-space: nowrap;;

          &.selected {
            background: $mainColor;
            color: $white;
          }
        }
      }
    }
  }


}

.error {
  border: 2px dashed $red;

  .radio-group-option {
    color: $red !important;
  }
}
