@import '../../constants/vars.scss';

.title-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include sm {
        flex-direction: column;
    }
}

.title-icon {
    width: 64px;
    height: 64px;
    left: 101px;
    top: 128px;

    background: $lightGrey;
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    margin-right: 20px;
}

.title-label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: $mainColor;

    @include sm {
        font-size: 18px;
        text-align: center;
        margin-top: 16px;
    }
}
